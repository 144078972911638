<template>
  <div :class="{'agency-table': true, ['sort-' + sortDirection]: true, ['sort-type-' + sortType]: true }">
    <div class="columns" ref="columns">
      <div class="column stretch-width">
        <div class="column-header column-header-agency">
          Agency
        </div>
        <a
          v-for="agency in sortedAgencies"
          :key="agency.index"
          class="agency-field row first"
          v-bind:class="{ highlight: selectedAgency ? (selectedAgency.id === agency.agency_id) : false }"
          v-on:click="openAgencyDetailsPage(agency.agency_id)"
          :href="'/agency?id=' + agency.agency_id">
          <p class="agency-index"> {{ agency.display_index }} </p>
          <div class="agency-name">
            <p class="agency-name-title"> <span class="agency-index-inner">{{ agency.index }}.&nbsp;</span>{{ agency.agency_name }} </p>
            <p class="agency-area-subtitle"> {{ agency.area_name }} </p>
          </div>
        </a>
      </div>
      <div class="column fixed-width">
        <div v-on:click="sortBy('size')" :class="{'column-header': true, 'sortable': true, 'sorted': sortType == 'size' }">
          <span>Size</span>
        </div>
        <div
          v-for="agency in sortedAgencies"
          :key="agency.index"
          class="row"
          v-bind:class="{ highlight: selectedAgency ? (selectedAgency.id === agency.agency_id) : false }"
          :href="'/agency?id=' + agency.agency_id">
          <span class="agency-size-long">{{ agency.size_class }}</span>
          <span class="agency-size-short">{{ agency.size_class_truncated }}</span>
        </div>
      </div>
      <div class="column fixed-width">
        <div v-on:click="sortBy('service')" :class="{'column-header': true, 'sortable': true, 'sorted': sortType == 'service' }">
          <span>Service</span>
        </div>
        <div
          v-for="agency in sortedAgencies"
          :key="agency.index"
          class="row"
          v-bind:class="{ highlight: selectedAgency ? (selectedAgency.id === agency.agency_id) : false }"
          :href="'/agency?id=' + agency.agency_id">
          <div class="square service">
            {{ agency.service }}%
          </div>
        </div>
      </div>
      <div class="column fixed-width">
        <div v-on:click="sortBy('ridership')" :class="{'column-header': true, 'sortable': true, 'sorted': sortType == 'ridership' }">
          <span>Riders</span>
        </div>
        <div
          v-for="agency in sortedAgencies"
          :key="agency.index"
          class="row last"
          v-bind:class="{ highlight: selectedAgency ? (selectedAgency.id === agency.agency_id) : false }"
          :href="'/agency?id=' + agency.agency_id">
          <div class="square ridership">
            {{ agency.ridership }}%
          </div>
        </div>
      </div>
    </div>
    <a v-if="showExpandButton" href="#" class="expand-button" v-on:click.prevent="expandOrCollapseTable()">
      {{ rowLimit ? "Expand table" : "Collapse table" }}
    </a>
    <p v-if="filteredAgencies.length === 0" class="empty-message">
      No agencies matching these filters
    </p>
  </div>
</template>

<script>
import * as stateAbbreviations from '../util/state-abbreviations.js'

export default {
  name: 'AgencyTable',
  props: {
    agencies: Array,
    selectedAgency: Object,
    selectedState: String,
    selectedSize: String
  },
  data() {
    return {
      rowLimit: this.selectedAgency ? 5 : 7,
      sortDirection: "descending",
      sortType: "size",
      sizes: {
        "L": "Large",
        "M": "Mid-size",
        "S": "Small",
        "XS": "Very small"
      },
      columnHeaders: []
    }
  },
  mounted(){
    var headers = this.$refs.columns.querySelectorAll(".column-header")
    for(var i = 0; i < headers.length; i++){
      this.columnHeaders.push(headers[i]);
    }
  },
  computed: {
    filteredAgencies() {
      return this.agencies.filter(agency => {
        const stateFilterValue = this.selectedState
          ? (stateAbbreviations[agency.meta.state] === this.selectedState)
          : true
        const sizeFilterValue = this.selectedSize
          ? (agency.meta.size_class === this.selectedSize)
          : true
        return stateFilterValue && sizeFilterValue
      })
    },
    sortedAgencies() {
      // Apply any selected filters
      const filteredAgencies = this.filteredAgencies

      // Format the agency fields for display in the table
      const formattedAgencies = filteredAgencies.map((agency) => {
        return {
          "agency_id": agency.id,
          "agency_name": agency.meta.dba || agency.meta.name,
          "area_name": agency.meta.uza_name,
          "size_class": this.sizes[agency.meta.size_class],
          "size_class_truncated": agency.meta.size_class,
          "size_value": agency.meta.size_value,
          "service": agency.values.vrh.percentage_now,
          "ridership": agency.values.upt.percentage_now
        }
      })

      // Apply current sorting criteria
      const sortedAgencies = formattedAgencies.sort((firstAgency, secondAgency) => {
        if (this.sortDirection === "ascending") {
          if (this.sortType === "service") {
            return firstAgency.service - secondAgency.service
          } else if (this.sortType === "ridership") {
            return firstAgency.ridership - secondAgency.ridership
          } else {
            // Sort by size by default
            return firstAgency.size_value - secondAgency.size_value
          }
        } else {
          if (this.sortType === "service") {
            return secondAgency.service - firstAgency.service
          } else if (this.sortType === "ridership") {
            return secondAgency.ridership - firstAgency.ridership
          } else {
            // Sort by size by default
            return secondAgency.size_value - firstAgency.size_value
          }
        }
      })

      // Add the index field
      var selectedAgencyIndex = null
      var lastValue;
      var currentRank = 0;
      var allRanks = 0;
      var indexedAgencies = (this.sortDirection === "descending" ? sortedAgencies : sortedAgencies.reverse()).map((agency, index, allAgencies) => {
        agency.index = index + 1

        allRanks++;

        var rankValue;
        if (this.sortType === "service") {
          rankValue = agency.service
        } else if (this.sortType === "ridership") {
          rankValue = agency.ridership
        } else {
          // Sort by size by default
          rankValue = agency.size_value
        }

        if(rankValue != lastValue){
          currentRank = allRanks;
        }

        lastValue = rankValue;
        agency.display_index = currentRank

        return agency
      })

      indexedAgencies = (this.sortDirection === "descending" ? indexedAgencies : indexedAgencies.reverse())

      for(var i = 0; i < indexedAgencies.length; i++){
        var agency = indexedAgencies[i]
        if (agency.agency_id === this.selectedAgency?.id) {
          selectedAgencyIndex = i
          break;
        }
      }

      var startIndex = 0
      if (selectedAgencyIndex) {
        if (selectedAgencyIndex < 2) {
          startIndex = 0
        } else if (selectedAgencyIndex > (indexedAgencies.length - 3)) {
          startIndex = indexedAgencies.length - 5
        } else {
          startIndex = selectedAgencyIndex - 2
        }
      }

      // Apply the appropriate row limit if table is collapsed
      return this.rowLimit ? indexedAgencies.slice(startIndex, startIndex + this.rowLimit) : indexedAgencies
    },
    showExpandButton() {
      return this.filteredAgencies.length > (this.selectedAgency ? 5 : 7)
    }
  },
  methods: {
    sortBy(newSortType,order) {
      if (newSortType === this.sortType && !order) {
        // Switch the sort direction
        this.sortDirection = (this.sortDirection === "ascending" ? "descending" : "ascending")
      }
      this.sortType = newSortType
      if(order){
        this.sortDirection = (order === "ascending" ? "ascending" : "descending")
      }
    },
    expandOrCollapseTable() {
      if (this.rowLimit) {
        this.rowLimit = null
      } else {
        this.rowLimit = (this.selectedAgency ? 5 : 7)
      }
    }
  }
}
</script>

<style scoped>
.agency-field {
  font-family: 'hero-new', sans-serif;
  display: flex;
  text-decoration: none;
  color: #2F4858;
  position: relative;
}
.agency-field:hover::before {
  position: absolute;
  width: auto;
  height: auto;
  border-radius: 8px;
  top: 8px;
  left: 8px;
  right: 8px;
  bottom: 8px;
  display: block;
  content: ' ';
  background-color: #F3F5F7;
  z-index: 90;
}
.agency-index {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  width: 70px;
  font-family: mono45-headline, monospace;
}

.sort-type-size .agency-index {
  font-size: 0;
  color: transparent;
  width: 8px;
  overflow: hidden;
}

.agency-name {
  width: 100%;
}

.agency-index, .agency-name {
  z-index: 100;
}
.agency-name-subtitle {
  font-size: 12px;
  opacity: 0.7;
}
.agency-name-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  display: block;
  max-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.agency-table {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  overflow-x: hidden;
  margin-top: 16px;
}
.column.fixed-width {
  width: 90px;
}
.column {
  display: flex;
  flex-direction: column;
}
.columns {
  display: flex;
}
.column-header {
  align-items: center;
  background-color: #E6EBEE;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 30px;
  padding: 0 8px;
  -webkit-user-select: none;
  user-select: none;
}
.empty-message {
  padding: 16px;
  text-align: center;
}
.expand-button {
  align-items: center;
  border: solid #C0C9CF;
  border-width: 0 1.5px 1.5px 1.5px;
  color: #049C9C;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}
.first {
  border-left: 1.5px solid #C0C9CF;
}
.highlight, .highlight:hover {
  background-color: #FCF4E8;
}
.highlight:hover::before {
  display: none;
}

.last {
  border-right: 1.5px solid #C0C9CF;
}
.ridership {
  background-color: #BF58BB;
}
.row {
  align-items: center;
  border-bottom: 1.5px solid #C0C9CF;
  display: flex;
  height: 80px;
  padding: 0 8px;
}
.service {
  background-color: #47BEC6;
}
.sortable:hover {
  background-color: #C2CED6;
  cursor: pointer;
}
.square {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  font-family: mono45-headline, monospace;
  font-size: 28px;
  font-weight: 700;
  height: 54px;
  justify-content: center;
  text-align: center;
  width: 58px;
}
.stretch-width {
  flex-grow: 1;
}
.sorted span {
  position: relative;
  padding-right: 8px;
}

.sorted span::after {
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  pointer-events: none;
  content: ' ';
  font-size: 0;
  right: -8px;
  top: 0;
  background-image: url(/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 16px;
}

.sort-ascending .sorted span::after {
  background-image: url(/dropdown-arrow-flipped.svg);
}

.agency-size-short {
  display: none;
  font-size: 14px;
  font-weight: 600;
  padding-left: 7px;
}

.agency-index-inner {
  display: none;
}

@media(max-width: 816px){
  .agency-name-title, .agency-area-subtitle {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 18px;
    display: block;
    max-width: 300px;
  }
}

.expand-button:hover {
  background-color: #EBF1F4;
}

.agency-size-long {
  font-size: 14px;
}

@media(max-width: 680px){
  .row {
    padding: 0 4px;
  }

  .agency-name-title, .agency-area-subtitle {
    white-space: normal;
    overflow: hidden;
    max-height: 36px;
    display: block;
    max-width: none;
    margin-top: 0;
    margin-bottom: 0;
  }

  .agency-area-subtitle {
    max-height: 18px;
    margin-top: 2px;
  }

  .agency-size-short {
    display: inline
  }

  .agency-size-long {
    display: none;
  }

  .row:last-child {
    border-bottom: 0;
  }


  .agency-index {
    font-size: 20px;
    width: 60px;
  }

  .first {
    border-left: 0;
    padding-left: 0;
  }

  .last {
    border-right: 0;
    padding-right: 0;
  }

  .expand-button {
    margin-top: 8px;
    border-width: 2px;
    border-radius: 48px;
    height: 44px;
    border-color: #049C9C;
  }

  .square {
    font-size: 20px;
    height: 44px;
    width: 48px;
  }

  .column.fixed-width {
    width: 70px;
  }
}

@media(max-width: 400px){
  .row {
    height: 64px;
  }

  .agency-area-subtitle {
    display: none;
  }

  .agency-table {
    max-width: calc(100% + 48px);
    width: calc(100% + 48px);
    margin-left: -24px;
  }

  .agency-index {
    display: none;
  }

  .agency-index-inner {
    display: inline;
  }

  .sort-type-size .agency-index-inner {
    display: none;
  }

  .column.fixed-width {
    width: 100px;
  }

  .square {
    font-size: 19px;
    height: 38px;
    width: 42px;
  }

  .first {
    padding-left: 12px;
  }

  .column-header-agency {
    padding-left: 12px;
  }

  .column-header {
    font-size: 12px;
    font-weight: 700;
    position: sticky;
  }

  .expand-button {
    margin-left: 8px;
    margin-right: 8px;
  }
}
</style>
