<template>
<div class="content-container footer">
<p>Data is from the National Transit Database, provided by the Federal Administration. Size of agencies is defined by pre-pandemic passenger trips taken per month. Website by Chris Arvin and Kat Siegal. <a href="/about">More about this site and data</a></p>
</div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
    
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
.content-container.footer {
    margin-top: 56px;
    margin-bottom: 56px;
    text-align: left;
}
</style>
