<template>
<nav :class='{ "small-search-open": smallSearchOpen }'>
    <div class="nav-inner">
        <a href="/" class="logo">Transit Recovery<span class="extra">&nbsp;in U.S.&nbsp;Cities</span></a>
        <a href="/about" class="about-link"></a>
        <a href="#"      class="search-link" v-on:click.prevent="openSmallSearch();" :style='{ opacity: search ? 1 : 0, pointerEvents: search ? "auto" : "none", transition: "all 0.2s ease-in-out" }'></a>
        <Search :style='{ opacity: search || smallSearchOpen ? 1 : 0, pointerEvents: search || smallSearchOpen ? "auto" : "none", transition: "all 0.2s ease-in-out" }'
                :agencies="agenciesForSearch"
                theme="minimal"
                ref="searchBar"
                v-on:close="smallSearchOpen = false" />
    </div>
</nav>
</template>

<script>
import Search from './Search.vue'

export default {
  name: 'Nav',
  components: {
      Search
  },
  props: ['agenciesForSearch','search'],
  data() {
    return {
        smallSearchOpen: false
    }
  },
  mounted() {

  },
  methods: {
      openSmallSearch(){ 
          this.smallSearchOpen = true
          var x = window.scrollX, y = window.scrollY;
          this.$refs.searchBar.focus();
          window.scrollTo(x, y);
      }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<style>
nav {
  width: 100%;
  height: 98px;
  padding: 0;
  margin: 0 auto;
  background: #2F4858
}

nav .nav-inner {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    height: 96px;
    box-sizing: border-box;
}

nav a.logo {
    float: left;
    display: block;
    color: white;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    margin-top: 28px;
    padding-left: 42px;
    background-image: url(/logo.svg);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 30px 30px;
}

nav a.about-link, nav a.search-link {
  float: right;
  display: block;
  height: 48px;
  width: 48px;
  background-image: url(/info.svg);
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 22px;
  margin-left: 20px;
}

@media(max-width: 700px){
    nav a.logo {
        line-height: 20px;
        font-size: 13px;
        max-width: 140px;
        text-align: left;
    }
}

@media (max-width: 500px){
    nav {
        height: 68px;
    }

    nav a.logo {
        margin-top: 13px;
        max-width: 60px;
    }

    nav a.logo .extra {
        display: none;
    }

    nav a.about-link, nav a.search-link {
        margin-top: 9px;
        margin-left: 8px;
    }

    nav .nav-inner {
        padding: 0 16px 0 24px
    }
}

nav a.about-link:hover, nav a.search-link:hover {
    background-color: rgba(0,0,0,0.25);
}

nav a.search-link  {
    background-image: url(/search_icon_full.svg);
}

nav div.search-area {
    float: right;
    width: auto;
    margin-top: 7px;
}

nav div.search-area:after {
    clear:  both;
    content: " ";
    display: block;
}

nav a.search-link {
    display: none;
}

@media(max-width: 570px){
    nav a.search-link {
        display: block;
    }

    nav div.search-area {
        float: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #2F4858;
        text-align: center;
        padding: 12px 72px 16px 24px;
        box-shadow: 0px 2px 12px rgba(0,0,0,0.4);
        box-sizing: border-box;
        margin: 0;
        pointer-events: none;
        transform: translate3d(0,-110%,0);
    }

    nav.small-search-open div.search-area {
        transform: translate3d(0,0,0);
    }

    nav div.search-area.minimal .search-bar input {
        width: 100%;
        border-color: rgba(255,255,255,0.3);
        font-size: 16px;
    }

    nav div.search-area.minimal .search-bar input:focus {
        border-color: white;
    }

    nav.small-search-open a.close-button {
        display: block;
        width: 48px;
        height: 48px;
        top: 12px;
        right: 12px;
        position: absolute;
        opacity: 1;
        pointer-events: all;

    }

    nav.small-search-open a.close-button:hover {
        background-color: rgba(0,0,0,0.2);
    }
}
</style>
