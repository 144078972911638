<template>
  <div class="table-section">
    <h2>{{ selectedAgency ? "Compared to other agencies" : "Explore the data" }}</h2>
    <p v-if="selectedAgency">Compare {{ selectedAgencyShortName }}'s transit service and ridership recovery to other agencies nationwide, or to agencies of its size or location.</p>
    <p v-if="!selectedAgency">Compare the percent of transit service hours provided and passenger trips taken {{ (monthString != '' ? ('in ' + monthString) : '') }} compared to before the pandemic.</p>
    <div class="filters">
      <button ref="allButton" v-on:click="clearFilters()" class="filter-button" v-bind:class="{ selected: isShowingAll }">
        All agencies
      </button>
      <button :class="{'filter-button': true, 'selected': selectedSize == selectedAgency.meta.size_class }" v-on:click="selectedSize = selectedAgency.meta.size_class; selectedState = ''" v-if="selectedAgency">
        Other {{ sizeNames[selectedAgency.meta.size_class].toLowerCase() }} agencies
      </button>
      <button :class="{'filter-button': true, 'selected': selectedState == stateAbbreviations[selectedAgency.meta.state] }" v-on:click="selectedState = stateAbbreviations[selectedAgency.meta.state]; selectedSize = ''" v-if="selectedAgency">
        Agencies in {{ selectedAgency.meta.state }}</button>
      <div class="filter-with-dropdown" v-if="!selectedAgency">
        <select ref="sizedrop" v-model="selectedSize" :class='{ "has-value": selectedSize != "" }' v-on:change="dropdownChange">
          <option value="">{{ sizePlaceholder }}</option>
          <option v-bind:key="size" v-for="size in sizes" :value="size">{{ sizeNames[size] }}</option>
        </select>
        <span class="dropdown-label">{{ selectedSize != '' ? sizeNames[selectedSize] : sizePlaceholder }}<span class="padding-fix"></span></span>
      </div>
      <div class="filter-with-dropdown" v-if="!selectedAgency">
        <select ref="statedrop" v-model="selectedState" :class='{ "has-value": selectedState != "" }' v-on:change="dropdownChange">
          <option value="">{{ statePlaceholder }}</option>
          <option v-bind:key="state" v-for="state in states">{{ state }}</option>
        </select>
        <span class="dropdown-label">{{ selectedState != '' ? selectedState : statePlaceholder }}<span class="padding-fix"></span></span>
      </div>
    </div>
    <p>Click the Size, Service or Ridership columns to sort agencies.</p>
    <AgencyTable ref="table" :agencies="agencies" :selectedAgency="selectedAgency" :selectedState="selectedState" :selectedSize="selectedSize"> </AgencyTable>
  </div>
</template>

<script>
import AgencyTable from './AgencyTable.vue'
import * as stateAbbreviations from '../util/state-abbreviations.js'

export default {
  name: 'TableSection',
  components: {
    AgencyTable
  },
  props: {
    agencies: Array,
    selectedAgency: Object,
    stickTo: Object,
    monthString: { type: String, default: '' }
  },
  data() {
    return {
      selectedState: "",
      selectedSize: "",
      stateAbbreviations: stateAbbreviations,
      sizePlaceholder: "By size",
      statePlaceholder: "By state",
      sizes: ["L", "M", "S", "XS"],
      sizeNames: {
        "L": "Large",
        "M": "Mid-size",
        "S": "Small",
        "XS": "Very small"
      }
    }
  },
  mounted(){
    this.forceUpdateDropdownWidth();

    // if(this.selectedAgency){
    //   this.selectedSize = this.selectedAgency.meta.size_class
    // }

    window.addEventListener("resize", this.forceUpdateDropdownWidth);
    this.setupStick();
  },
  methods: {
    forceUpdateDropdownWidth(){
      this.dropdownChange(this.$refs.sizedrop)
      this.dropdownChange(this.$refs.statedrop)
    },
    clearFilters(){
      this.selectedSize = ''
      this.selectedState = ''
    },
    dropdownChange(e){
      if(!e){ return }
      var select = e.target ? e.target : e;
      var label = select.parentNode.querySelector(".dropdown-label");
      if(!label){ return }

      var width = Math.round(label.getBoundingClientRect().width) + "px"
      select.style.width = width
    },
    sortBy(type,order){
      this.$refs.table.sortBy(type,order);
    },
    resetFilters(){
      this.selectedState = ''
      if(this.selectedAgency){
        this.selectedSize = this.selectedAgency.meta.size_class
      } else {
        this.selectedSize = ''
      }
    },
    setupStick(){
      if(this.stickTo){
        var lastKnownScrollPosition = 0;
        var ticking = false;
        var onUpdateFunction = (e) => {
            lastKnownScrollPosition = window.scrollY;

            if (!ticking) {
              window.requestAnimationFrame(() => {
                ticking = false;
              });

              ticking = true;
            }

            this.$refs.table.columnHeaders.forEach((columnHeader) => {
              var columnRect = columnHeader.getBoundingClientRect()
              var navRect = this.stickTo.$el.getBoundingClientRect();
              var parentRect = columnHeader.parentNode.getBoundingClientRect();

              var navBelowZero = Math.min(navRect.top,0)
              var change = Math.max(0,-1 * (parentRect.top - navRect.bottom + navBelowZero))
              columnHeader.style.transform = 'translate3d(0,' + change + 'px,0)'
              if(columnHeader.style.zIndex != 1200){
                columnHeader.style.zIndex = 1200;
              }
            })
        };
        document.addEventListener('scroll', onUpdateFunction)
        window.addEventListener('resize', onUpdateFunction)
      }
    }
  },
  computed: {
    states() {
      const uniqueStates = [...new Set(this.agencies.map(item => stateAbbreviations[item.meta.state]))]
      return uniqueStates.sort((first, second) => first.localeCompare(second))
    },
    isShowingAll() {
      return this.selectedState == '' && this.selectedSize == ''
    },
    selectedAgencyShortName(){
      return (this.selectedAgency ? this.selectedAgency.meta.acronym ? this.selectedAgency.meta.acronym : this.selectedAgency.meta.friendlyName : '')
    },
  },
  watch: {
    selectedState() {
      this.forceUpdateDropdownWidth();
    },
    selectedSize() {
      this.forceUpdateDropdownWidth();
    },
    stickTo() {
      this.setupStick();
    }
  }
}
</script>

<style scoped>
h2 {
  margin: 0px;
  text-align: left;
}
.dropdown {
  align-items: stretch;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 3px 12px rgba(0,0,0,0.1), 0px 10px 40px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 56px;
  z-index: 999;
}
.dropdown-item {
  cursor: pointer;
  display: flex;
  font-weight: 600;
  margin: 0;
  padding: 8px 16px;
}
.dropdown-item:hover {
  background-color: #F3F5F7;
}

.filter-button, .filter-with-dropdown select, .filter-with-dropdown .dropdown-label {
  align-items: center;
  background: none;
  border: 2px solid #2F4858;
  border-radius: 18px;
  color: #2F4858;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 36px;
  line-height: 32px;
  padding: 0 20px;
  font-family: 'hero-new', sans-serif;
  font-weight: 600;
}

.filter-with-dropdown select {
  padding-left: 18px;
  padding-right: 40px;
  background-image: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: url(/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 19px top 13px;
}

.filter-with-dropdown .dropdown-label {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  padding: 0;
  margin: 0;
  text-indent: 0;
  white-space: nowrap;
}

.dropdown-label .padding-fix {
  display: inline-block;
  width: 66px;
  height: 9px;
  vertical-align: top;
}

.filter-with-dropdown select.has-value {
  background-color: #2F4858;
  color: white;
  background-image: url(/dropdown-arrow-white.svg);
}

select::-ms-expand {
  display: none;
}

.filter-button:hover, select:hover {
  background-color: #EBF1F4;
}

.filter-button.selected {
  background: #2F4858;
  color: white;
  font-weight: bold;
  cursor: default;
}
.filter-with-dropdown {
  display: flex;
  position: relative;
}

.filters {
  padding-bottom: 12px;
}

.filters > * {
  display: inline-block;
  margin-right: 8px;
  margin-top: 12px;
  vertical-align: top;
}

.filters > *:last-child {
  margin-right: 0;
}

.table-section {
  margin: 64px 0 0 0;
}

select:invalid { color: gray; }

@media(max-width: 480px){
  .filter-button, .filter-with-dropdown select, .filter-with-dropdown .dropdown-label {
    font-size: 13px;
    padding: 0 12px;
  }

  .dropdown-label .padding-fix {
    width: 30px;
  }
}
</style>
