module.exports = {
    monthsForValue: function(values,valname,startMonth){
      var result = []
      var monthsDone = []
      var valueSet = values[valname]
      if(startMonth){
          valueSet = valueSet.filter(startMonthFilter(startMonth))
      }

      valueSet.forEach((modeMonth) => {
        var midMonthDate = addDays(new Date(Date.parse(modeMonth.month)),10)
        
        var monthString = midMonthDate.toLocaleDateString('en-EN',{ year: 'numeric', month: 'short' }).replace(/\s{1}[0-9]{2}/g,' ')
        if(monthsDone.indexOf(monthString) < 0){ result.push(midMonthDate.toISOString()); monthsDone.push(monthString) }
      })

      return result
    },
    monthString: function(month){
        var midMonthDate = addDays(new Date(Date.parse(month)),10)
        return midMonthDate.toLocaleDateString('en-EN',{ year: 'numeric', month: 'short' })
    },
    transformedArrayForValue: function(values,valname,startMonth){
      var valueSet = values[valname]
      if(startMonth){
          valueSet = valueSet.filter(startMonthFilter(startMonth))
      }

      var valuesByMonth = {};
      valueSet.forEach((modeMonth) => {
        if(!valuesByMonth[modeMonth.month]){ valuesByMonth[modeMonth.month] = 0 }
        valuesByMonth[modeMonth.month] += modeMonth.value
      })

      return Object.keys(valuesByMonth).map((monthString) => {
        var daysInThisMonth = daysInMonth(monthString);
        return Math.round(valuesByMonth[monthString] / daysInThisMonth)
      })
    },
    arrayByModeForValue: function(values,valname,startMonth){
      var valueSet = values[valname]
      if(startMonth){
          valueSet = valueSet.filter(startMonthFilter(startMonth))
      }

      var valuesByMode = {};
      var months = {};

      valueSet.forEach((modeMonth) => {
        var thisMode = nameForMode(modeMonth.mode)
        if(!valuesByMode[thisMode]){
          valuesByMode[thisMode] = {}
        }
        months[modeMonth.month] = true;
        if(!valuesByMode[thisMode][modeMonth.month]){ valuesByMode[thisMode][modeMonth.month] = 0 }
        valuesByMode[thisMode][modeMonth.month] += modeMonth.value
      })

      var modes = Object.keys(valuesByMode)
      months = Object.keys(months)

      var finalArrays = {}
      months.forEach((monthString) => {
        var daysInThisMonth = daysInMonth(monthString);
        modes.forEach((modeKey) => {
          if(!finalArrays[modeKey]){ finalArrays[modeKey] = [] }

          if(valuesByMode[modeKey][monthString]){
            finalArrays[modeKey].push([monthString,Math.round(valuesByMode[modeKey][monthString] / daysInThisMonth)])
          } else {
            finalArrays[modeKey].push([monthString,0])
          }
        })
      })

      var result = Object.keys(finalArrays).map((modeKey) => {
        return { 
          mode: modeKey,
          data: finalArrays[modeKey]
        }
      })

      console.log(result)

      return result
    },
    daysInMonth: daysInMonth,
    nameForMode: nameForMode
  }

function daysInMonth(monthString) {
    var parsed = addDays(new Date(Date.parse(monthString)),10)
    var year = parsed.getYear()
    var month = parsed.getMonth()
    return new Date(year, month + 1, 0).getDate();
}

function startMonthFilter(startMonth){
    return ((value) => { 
        return new Date(Date.parse(value.month)) >= new Date(Date.parse(startMonth))
    });
}

function addDays(input,days) {
    var date = new Date(input.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function nameForMode(modeKey){
  var names = {
    'CC': "Cable Car",
    'SR': "Streetcar",
    'MB': "Bus",
    'TB': "Bus",
    'RB': "Bus Rapid Transit",
    'LR': "Light Rail",
    'HR': "Heavy Rail",
    'MG': "Monorail or Automated",
    'YR': "Hybrid Rail",
    'CR': "Commuter Rail",
    'FB': "Ferry",
    'CB': "Commuter Bus"

  }

  if(names[modeKey]){
    return names[modeKey]
  } else {
    return "Other (" + modeKey + ")"
  }
}