<template>
<div :class="{ 'search-area': true, 'minimal': theme == 'minimal' }">
    <div id="search-bar" class="search-bar">
    <form v-on:submit.prevent="goToFirstResult">
    <input
        v-model="searchText"
        placeholder="Find a transit agency."
        spellcheck="false"
        autocorrect="off"
        type="search"
        v-on:focus="focused = true"
        v-on:blur="focused = false"
        ref="input"
    />
    </form>
    <ul class="agencies" :style="{ display: focused || resultsHovered ? 'block' : 'none' }" v-on:mousemove="resultsHovered = true" v-on:mouseout="resultsHovered = false">
        <li v-for="agency in searchResults" :key="agency.id">
        <a v-bind:href="'/agency?id=' + agency.id">
            <span class="result-title">{{ agency.meta.dba || agency.meta.name }}</span>
            <span class="result-subtitle">{{ agency.meta.uza_name }}</span>
        </a>
        </li>
    </ul>
    </div>
    <a href="#" v-on:click.prevent="$emit('close')" class="close-button"></a>
</div>
</template>

<script>
import Fuse from 'fuse.js'

export default {
  name: 'Search',
  components: {

  },
  props: ['agencies','theme'],
  data() {
    return {
        fuse: new Fuse(this.agencies, {
            keys: [
                "meta.dba",
                "meta.name",
                "meta.acronym",
                "meta.city",
                "meta.uza_name",
            ],
            includeScore: true,
            minMatchCharLength: 2,
            threshold: 0.3
            }),
        searchText: "",
        focused: false,
        resultsHovered: false
    }
  },
  mounted() {

  },
  methods: {
    goToFirstResult() {
        if(!this.searchResults || this.searchResults.length < 1){ return }

        window.location.href = '/agency?id=' + this.searchResults[0].id
    },
    focus(){
        this.$refs.input.focus();
    }
  },
  computed: {
    searchResults() {
      if (this.fuse && this.agencies) {
        const results = this.fuse.search(this.searchText)

        return results
                .map(result => result.item)
                .slice(0,5)
      }
      return []
    }
  },
  watch: {
    agencies() {
      if (this.fuse && this.agencies) {
        this.fuse.setCollection(this.agencies)
      }
    }
  }
}
</script>

<style>
.search-area {
  width: 100%;
  color: white;
  padding: 18px 0 0 0;
  overflow: visible;
}

.search-bar {
  width: 100%;
  overflow: visible;
  position: relative;
}

.search-bar input {
  background: none;
  width: 100%;
  display: block;
  border: 2px solid rgba(255,255,255,0.7);
  border-radius: 4px;
  height: 24px;
  line-height: 20px;
  padding: 22px 0 22px 40px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  outline: none;
  font-family: 'hero-new';
  background-image: url('/search_icon.svg');
  background-position: left center;
  background-repeat: no-repeat;
}

.minimal .search-bar input {
   border-color: rgba(255,255,255,0);
   border-top: 0;
   border-left: 0;
   border-right: 0;
   border-radius: 0;
   border-bottom-width: 1.5px;
   width: auto;
   margin: 0;
   background-position: left top 1px;
}

.minimal .search-bar input:hover {
    border-color: rgba(255,255,255,0.4);
}

.search-bar input:focus, .minimal .search-bar input:focus {
  outline: none;
  border-color: white;
}

.search-bar input::placeholder {
  color: rgba(255,255,255,0.7);
  opacity: 1;
}

.search-area ul.agencies {
  display: block;
  text-align: left;
  width: 100%;
  background: white;
  position: absolute;
  top: 56px;
  z-index: 3000;
  box-shadow: 0px 3px 12px rgba(0,0,0,0.1), 0px 10px 40px rgba(0,0,0,0.1);
  border-radius: 5px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.agencies li {
  display: block;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

ul.agencies li a {
  display: block;
  height: auto;
  color: #2F4858;
  text-decoration: none;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid #dadada;
}

ul.agencies li a:hover {
  background-color: rgba(47,72,88,0.07)
}

ul.agencies li:last-of-type a {
  border-bottom: none;
}

ul.agencies li a span.result-title {
  font-weight: bold;
  font-size: 16px;
  display: block;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.agencies li a span.result-subtitle {
  font-weight: normal;
  font-size: 14px;
  display: block;
  line-height: 20px;
}

@media(max-width: 680px){
    ul.agencies li a span.result-title {
        font-size: 14px;
        line-height: 18px;
        white-space: normal;
        max-height: 54px;
    }

    ul.agencies li a span.result-subtitle {
        font-size: 14px;
        line-height: 18px;
        padding-top: 4px;
    }
}

.minimal ul.agencies li a span.result-title {
  font-size: 13px;
  line-height: 18px;
  white-space: normal;
  max-height: 54px;
}

.minimal ul.agencies li a span.result-subtitle {
  display: none;
}

a.close-button {
    display: none;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    background: url(/x.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
}
</style>
